export class EventUtils {
  private static _lastClickTime = 0;

  public static get isDoubleClick(): boolean {
    const now = new Date().getTime();
    const timespan = now - this._lastClickTime;
    this._lastClickTime = now;
    return timespan < 250;
  }
}
